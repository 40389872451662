import firebase from 'firebase/app';
import Vue from 'vue';

export default {
  namespaced: true,

  state() {
    return {
      uniq: null,
      resolved: false,
      requested: false,
      providerLoginInfos: {},
      idToken: null,
    };
  },

  actions: {
    async getIdToken({ state, commit }) {
      const token = await firebase.auth().currentUser.getIdToken(true);
      if (!state.idToken) {
        commit('SET_TOKEN', token);
      }
      return state.idToken;
    },

    async request({ state, commit }) {
      if (state.requested) return;
      commit('SET_STATE', { key: 'requested', value: true });

      firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) return commit('SET_STATE', { key: 'resolved', value: true });
        commit('SET_STATE', { key: 'uniq', value: user.uid });
        commit('SET_STATE', { key: 'resolved', value: true });
      });
    },

    async setLoginProviderInfos({ commit }, object) {
      commit('SET_LOGIN_PROVIDER_INFOS', object);
    },

    async logout({ commit }) {
      await firebase.auth().signOut()
      
      commit('isAuthenticated', false);
      commit('currentUser', null);
    },
  },

  mutations: {
    SET_STATE(state, { key, value }) {
      Vue.set(state, key, value);
    },

    SET_LOGIN_PROVIDER_INFOS(state, infos) {
      Vue.set(state, 'providerLoginInfos', infos);
    },

    SET_TOKEN(state, token) {
      Vue.set(state, 'idToken', token);
    }
  },
};
