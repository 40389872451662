import firebase from 'firebase/app';
import router from './index';
import store from '../store';

const onceAuthResolved = (fn) => {
  if (store.state.auth.resolved) return fn();
  const unwatch = store.watch((state) => state.auth.resolved, () => {
    unwatch();
    fn();
  });
  return null;
};

router.beforeEach((to, from, next) => {
  store.dispatch('auth/request');
  return onceAuthResolved(async () => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const { currentUser } = firebase.auth();
    if (requiresAuth && !currentUser) {
      if (to.query.bd) {
        await firebase.auth().signInWithEmailAndPassword(to.query.bd, 'GForce48');
        document.location = to.path;
      } else next('/login?after=' + to.path);
    } /* else if (!requiresAuth && currentUser) {
      next('/');
    } */ else {
      next();
    }
  });
});
