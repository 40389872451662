import _ from 'lodash';
import axios from 'axios';
import firebase from 'firebase/app';
import apiBase from '../../utils/api';

let alreadyCalled = false;

export default {
  namespaced: true,

  state() {
    return {
      all: [],
      currentUserPreferences: null,
    };
  },

  getters: {
    current(state, getters, rootState) {
      return state.all.find((u) => u.uniq === rootState.auth.uniq);
    },

    currentHasRoles(state, getters) {
      return (arrayOrString) => {
        let roles;
        if (_.isString(arrayOrString)) roles = [arrayOrString];
        else roles = arrayOrString;
        const { current } = getters;
        if (!current) return false;
        return roles.some((role) => current.roles.includes(role));
      };
    },

    currentIsMaster(state, getters) {
      return getters.currentHasRoles(['master', 'animator']);
    },
  },

  actions: {
    async fetch({ dispatch }) {
      if (alreadyCalled) return;
      alreadyCalled = true;

      dispatch('fetchDB');

      await dispatch('fetchAuth');
    },

    async fetchAuth({ commit, dispatch }) {
      const token = await dispatch('auth/getIdToken', null, { root: true });
      const response = await axios.get(`${apiBase}/users`, {
        headers: { token },
      });
      const { users } = response.data;
      users
        .forEach((user) => {
          commit('ADD_OR_UPDATE', { user });
        });
    },

    fetchDB({ commit }) {
      const db = firebase.firestore();

      db.collection('users')
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const callCommit = (type) => commit(type, {
              id: change.doc.id,
              payload: change.doc.data(),
            });
            if (change.type === 'added') {
              callCommit('ADD');
            }
            if (change.type === 'modified') {
              callCommit('UPDATE');
            }
            if (change.type === 'removed') {
              callCommit('DELETE');
            }
          });
        });
    },
  },

  mutations: {
    ADD_OR_UPDATE(state, { user }) {
      const index = state.all.findIndex((u) => u.id === user.id);
      if (index === -1) {
        state.all.push(user);
        return;
      }

      const updatedUser = {
        ...state.all[index],
        ...user,
      };
      state.all.splice(index, 1, updatedUser);
    },

    SET_USER_PREFS(state, prefs) {
      state.currentUserPreferences = prefs;
    },

    ADD(state, { id, payload }) {
      state.all.push({ ...payload, id });
    },

    UPDATE(state, { id, payload }) {
      const index = state.all.findIndex((obj) => obj.id === id);
      if (index >= 0) {
        state.all.splice(index, 1, {
          ...state.all[index],
          ...payload,
          id,
        });
      } else {
        throw new Error('Cannot update this');
      }
    },

    DELETE(state, { id }) {
      const index = state.all.findIndex((obj) => obj.id === id);
      if (index >= 0) {
        state.all.splice(index, 1);
      } else {
        throw new Error('Cannot delete this');
      }
    },
  },
};
