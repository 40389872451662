import firebase from 'firebase/app';
import omit from 'lodash.omit';

let alreadyCalled = false;

function convert(data) {
  const val = { ...data };
  if (val.start) val.start = data.start.toDate();
  return val;
}

export default {
  namespaced: true,

  state() {
    return [];
  },

  actions: {
    async create(context, { game }) {
      const db = firebase.firestore();
      const docRef = await db.collection('games').add(game);
      return docRef.id;
    },

    async update(context, { id, game }) {
      const db = firebase.firestore();
      const ref = db.collection('games').doc(id);
      if (game.privateItems) {
        await ref.collection('private-items').doc('default').set(game.privateItems || {});
      }
      await ref.update(omit(game, 'privateItems'));
    },

    async fetchUpcoming({ commit }) {
      const db = firebase.firestore();

      const snapshot = await db
        .collection('games')
        .where('start', '>=', new Date())
        .where('published', '==', true)
        .orderBy('start')
        .get();

      if (snapshot.empty) {
        return;
      }  
      
      snapshot.forEach(doc => {
        commit('ADD_OR_UPDATE', { game: { ...convert(doc.data()), id: doc.id } });
      });
    },

    async fetchBySlug({ commit }, slug) {
      const db = firebase.firestore();

      const snapshot = await db
        .collection('games')
        .where('slug', '==', slug)
        .get();

      if (snapshot.empty) {
        return;
      }  
      
      snapshot.forEach(doc => {
        commit('ADD_OR_UPDATE', { game: { ...convert(doc.data()), id: doc.id } });
      });      
    },

    async fetchById({ commit }, { id, getPrivateData = false }) {
      const db = firebase.firestore();

      const ref = db.collection('games').doc(id);

      ref.onSnapshot(async snapshot => {
        const data = snapshot.data();

        if (getPrivateData) {
          const snapshot = await ref.collection('private-items').doc('default').get();
          data.privateItems = snapshot.data();
        }
        commit('ADD_OR_UPDATE', { game: { ...convert(data), id } });
      })
    },

    fetch({ commit }) {
      if (alreadyCalled) return;
      alreadyCalled = true;
      const db = firebase.firestore();

      db.collection('games')
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const callCommit = (type) => commit(type, {
              game: { 
                ...convert(change.doc.data()), 
                id: change.doc.id,
              }
            });
            if (change.type === 'added') {
              callCommit('ADD_OR_UPDATE');
            }
            if (change.type === 'modified') {
              callCommit('ADD_OR_UPDATE');
            }
            if (change.type === 'removed') {
              callCommit('DELETE');
            }
          });
        });
    },
  },

  mutations: {
    ADD_OR_UPDATE(state, params) {
      const game = params.game;
      const index = state.findIndex((u) => u.id === game.id);
      if (index === -1) {
        state.push(game);
        return;
      }

      const updatedGame = {
        ...state[index],
        ...game,
      };
      state.splice(index, 1, updatedGame);
    },

    DELETE(state, { id }) {
      const index = state.findIndex((obj) => obj.id === id);
      if (index >= 0) {
        state.splice(index, 1);
      } else {
        throw new Error('Cannot delete this');
      }
    },
  },
};
