import Vue from 'vue'
import { 
  Modal, 
  Dialog, 
  Table, 
  Datetimepicker, 
  Icon, 
  Taginput, 
  Switch, 
  ConfigProgrammatic,
  Radio,
} from 'buefy';
import App from './App.vue'
import router from './router'
import store from './store'

import './styles/app.scss';
import '@fortawesome/fontawesome-free/css/all.css';

import './bootstraps'

Vue.config.productionTip = false

Vue.use(Dialog);
Vue.use(Table);
Vue.use(Datetimepicker);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Taginput);
Vue.use(Switch);
Vue.use(Radio);
ConfigProgrammatic.setOptions({
  defaultIconPack: 'fas',
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
