import firebase from 'firebase/app';
import Vue from 'vue';

let alreadyCalled;

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async fetch({ commit }) {
      if (alreadyCalled) return;
      alreadyCalled = true;
      const db = firebase.firestore();
      const { currentUser } = firebase.auth();
      const user = currentUser.uid;

      db.collection('reminders')
        .where('user', '==', user)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const { game, choice } = change.doc.data();
            const callCommit = () => commit('ADD_OR_UPDATE', {
              key: game,
              val: choice,
            });
            if (change.type === 'added') {
              callCommit();
            }
            if (change.type === 'modified') {
              callCommit();
            }
            if (change.type === 'removed') {
              commit('ADD_OR_UPDATE', { key: game, val: null });
            }
          });
        });
    },
  },

  mutations: {
    ADD_OR_UPDATE(state, { key, val }) {
      Vue.set(state, key, val);
    },
  },
};
