import firebase from 'firebase/app';

export default {
  namespaced: true,

  state() {
    return [];
  },

  actions: {
    async create(context, { content }) {
      const db = firebase.firestore();
      const docRef = await db.collection('contents').add(content);
      return docRef.id;
    },

    async update(context, { id, content }) {
      const db = firebase.firestore();
      const ref = db.collection('contents').doc(id);
      return ref.update(content);
    },

    async fetchUpcoming({ commit }) {
      const db = firebase.firestore();

      const snapshot = await db
        .collection('contents')
        .where('start', '>=', new Date())
        .where('published', '==', true)
        .orderBy('start')
        .get();

      if (snapshot.empty) {
        return;
      }  
      
      snapshot.forEach(doc => {
        commit('ADD_OR_UPDATE', { content: { ...doc.data(), id: doc.id } });
      });      
    },

    async fetchAll({ commit }) {
      const db = firebase.firestore();

      const snapshot = await db
        .collection('contents')
        .get();

      if (snapshot.empty) {
        return;
      }  
      
      snapshot.forEach(doc => {
        commit('ADD_OR_UPDATE', { content: { ...doc.data(), id: doc.id } });
      });      
    },

    async fetchById({ commit }, id) {
      const db = firebase.firestore();

      const ref = db.collection('contents').doc(id);
      const snapshot = await ref.get();
      const data = snapshot.data();

      commit('ADD_OR_UPDATE', { content: { ...data, id } });
    },
  },

  mutations: {
    ADD_OR_UPDATE(state, params) {
      const content = params.content;
      const index = state.findIndex((u) => u.id === content.id);
      if (index === -1) {
        state.push(content);
        return;
      }

      const updatedContent = {
        ...state[index],
        ...content,
      };
      state.splice(index, 1, updatedContent);
    },

    ADD(state, { id, payload }) {
      state.push({ ...payload, id });
    },

    UPDATE(state, { id, payload }) {
      const index = state.findIndex((obj) => obj.id === id);
      if (index >= 0) {
        state.splice(index, 1, {
          ...state[index],
          ...payload,
          id,
        });
      } else {
        throw new Error('Cannot update this');
      }
    },

    DELETE(state, { id }) {
      const index = state.findIndex((obj) => obj.id === id);
      if (index >= 0) {
        state.splice(index, 1);
      } else {
        throw new Error('Cannot delete this');
      }
    },
  },
};
