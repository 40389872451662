import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../components/login/Index.vue'),
    redirect: { name: 'LoginConnect' },
    children: [
      {
        path: 'connect',
        name: 'LoginConnect',
        component: () => import(/* webpackChunkName: "login" */ '../components/login/Connect.vue'),
      },
    ],
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      store.dispatch('auth/logout').then(() => {
        document.location = '/';
      });
    },
  },
  {
    path: '/',
    redirect: { name: 'Games' },
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "admin" */ '../components/admin/admin-layout/Index.vue'),
    children: [
      {
        path: 'games',
        name: 'Games',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/games/Index.vue'),
        meta: {
          requiresAuth: true,
        },
      },    
      {
        path: 'games/new',
        name: 'NewGame',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/games/Form.vue'),
        props: { gameId: '' },
        meta: {
          requiresAuth: true,
        },
      },    
      {
        path: 'games/:gameId',
        name: 'GameEdit',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/games/Form.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },    
      {
        path: 'contents',
        name: 'Contents',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/contents/Index.vue'),
        meta: {
          requiresAuth: true,
        },
      },    
      {
        path: 'contents/:contentId',
        name: 'ContentEdit',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/contents/Form.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'tests',
        name: 'Tests',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/tests/Index.vue'),
        props: { gameId: '' },
        meta: {
          requiresAuth: true,
        },
      },    
    ],
  },
  { 
    path: '*', 
    component: () => import(/* webpackChunkName: "base" */ '../components/page-not-found/Index.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'is-active',
  base: process.env.BASE_URL,
  routes
})

export default router
