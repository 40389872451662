import Vue from 'vue';
import Vuex from 'vuex';

import users from './modules/users';
import auth from './modules/auth';
import games from './modules/games';
import contents from './modules/contents';
import reminders from './modules/reminders';
import tests from './modules/tests';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    auth,
    games,
    contents,
    reminders,
    tests,
  },
});
