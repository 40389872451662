import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyCmbTd12IqLFFmTZrT2XRgWR3pujMwFT84",
  authDomain: "qwiz-tv-2.firebaseapp.com",
  projectId: "qwiz-tv-2",
  storageBucket: "qwiz-tv-2.appspot.com",
  messagingSenderId: "422592080432",
  appId: "1:422592080432:web:e9b064bef5e4016c54dbf5",
  measurementId: "G-LJ6NXTDQPD"
};

firebase.initializeApp(config);
firebase.analytics();
