import firebase from 'firebase/app';

let alreadyCalled = false;

export default {
  namespaced: true,

  state() {
    return {
      items: [],
      command: {
        command: null,
        n: 0,
      },
    };
  },

  actions: {
    fetchCommand({ commit }) {
      const db = firebase.firestore();
      const ref = db.collection('commands').doc('main');
      ref.onSnapshot(async snapshot => {
        const data = snapshot.data();
        commit('UPDATE_COMMAND', data);
      })

    },

    fetch({ commit }) {
      if (alreadyCalled) return;
      alreadyCalled = true;
      const db = firebase.firestore();

      db.collection('cells')
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const callCommit = (type) => commit(type, { id: change.doc.id, payload: change.doc.data() });
            if (change.type === 'added') {
              callCommit('ADD_OR_UPDATE');
            }
            if (change.type === 'modified') {
              callCommit('ADD_OR_UPDATE');
            }
            if (change.type === 'removed') {
              callCommit('DELETE');
            }
          });
        });
    },
  },

  mutations: {
    ADD_OR_UPDATE(state, { id, payload }) {
      const index = state.items.findIndex((u) => u.id === id);
      if (index === -1) {
        state.items.push({ ...payload, id });
        return;
      }

      const updated= {
        ...state.items[index],
        ...payload,
        id,
      };
      state.items.splice(index, 1, updated);
    },

    DELETE(state, id) {
      const index = state.items.findIndex((obj) => obj.id === id);
      if (index >= 0) {
        state.items.splice(index, 1);
      } else {
        throw new Error('Cannot delete this');
      }
    },

    UPDATE_COMMAND(state, payload) {
      state.command = payload;
    },
  },
};
